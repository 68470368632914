'use client'

import { useState, useRef, useEffect, useCallback } from 'react'

import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { ButtonAsA } from '@b-stock/bstock-react'
import { Breakpoints, Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

import type { HeroBannerDataProps } from './heroBannerMockData'

const HeroBannerCarouselContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 18.75rem; // fixed height
`

const HeroBannerCarouselTrack = styled.div<{
  $isTransitionEnabled: boolean
  $currentSlide: number
}>`
  display: flex;
  height: 100%;
  transition: ${({ $isTransitionEnabled }) =>
    $isTransitionEnabled ? 'transform 0.5s ease' : 'none'};
  transform: translateX(-${({ $currentSlide }) => $currentSlide * 100}%);
`

const HeroBannerCarouselSlide = styled.div<{
  $hasCta: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ $hasCta }) => ($hasCta ? 'start' : 'center')};
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0.5rem 4rem;
  flex: 0 0 100%;

  @media ${Breakpoints.max.small} {
    padding: 0.5rem 2.5rem;
  }
`

const HeroBannerCarouselSlideImage = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 1;
`

const HeroBannerCarouselSlideContent = styled.div<{
  $hasCta: boolean
}>`
  position: relative;
  text-align: ${({ $hasCta }) => ($hasCta ? 'left' : 'center')};
  color: white;
  z-index: 3;

  @media ${Breakpoints.max.small} {
    max-width: 100%;
    text-align: center;
  }

  @media ${Breakpoints.min.medium} {
    width: 77.75rem; // fixed width
    margin: auto;
  }
`

const HeroBannerCarouselSlideTitle = styled.h2`
  ${Typography.Title1}
  margin: 0;
  color: ${designColors.neutral.white};
`

const HeroBannerCarouselSlideSubtitle = styled.p`
  ${Typography.Subtitle1}
  color: ${designColors.neutral.white};
`

const HeroBannerCarouselSlidePrimaryBtn = styled(ButtonAsA)`
  margin-right: 1rem;
`
const HeroBannerCarouselSlideSecondaryBtn = styled(ButtonAsA)`
  color: ${designColors.neutral.white};
  border-color: ${designColors.neutral.white};
`

const HeroBannerCarouselNavBtn = styled.button<{
  $side: 'left' | 'right'
}>`
  position: absolute;
  ${({ $side }) => $side}: 0;
  top: 50%;
  margin: 0 0.75rem;
  border: none;
  background-color: transparent;
  color: ${designColors.neutral.mediumGray};
  transform: translateY(-50%);
  font-size: 2.5rem;
  cursor: pointer;
  z-index: 30;

  @media ${Breakpoints.max.small} {
    font-size: 2rem;
    margin: 0;
    padding: 0 0.375rem;
    opacity: 60%;
  }
`

/**
 * Hero Banner Carousel component. Takes in an array of hero banner objects
 * containing a title, subtitle, banner image, and optional cta links. Banners
 * automatically rotate every 8 seconds by default with an option to increase
 * the interval time. Banners seamlessly rotate in an 'infinite' loop.
 */
const HeroBannerCarousel = ({
  banners,
  rotateInterval = 8000,
}: HeroBannerDataProps) => {
  const intl = useIntl()

  const [currentSlide, setCurrentSlide] = useState(1)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const heroBannerTrackRef = useRef<HTMLDivElement>(null)

  const nextSlide = useCallback(() => {
    if (!isTransitioning) {
      setIsTransitioning(true)
      setCurrentSlide((prevSlide) => prevSlide + 1)
    }
  }, [isTransitioning])

  const prevSlide = useCallback(() => {
    if (!isTransitioning) {
      setIsTransitioning(true)
      setCurrentSlide((prevSlide) => prevSlide - 1)
    }
  }, [isTransitioning])

  const onTransitionEnd = () => {
    setIsTransitioning(false)

    // replace cloned first banner with first banner in array without a transition effect
    if (currentSlide === banners.length + 1) {
      setCurrentSlide(1)
    }

    // replace cloned last banner with last banner in array without a transition effect
    if (currentSlide === 0) {
      setCurrentSlide(banners.length)
    }
  }

  const { trackButtonClicked } = useAnalytics()

  const handlePrimaryButtonClick = useCallback(
    (href: string, buttonText: string) => {
      // Segment analytics event and metadata transcribed from old wordpress code
      // TODO: Update entity_type to based on the role of the user once we
      // implement user authentication.
      trackButtonClicked(
        'homepage', // screen_name
        'banner_cta', // button_name
        'home_portal', // source
        'buyer', // entity_type
        {
          cta_name: buttonText.toLowerCase().replace(/\s+/g, '_'),
          url: document.URL,
          referrer: document.referrer,
          target_url: href,
        }
      )
    },
    [trackButtonClicked]
  )

  useEffect(() => {
    const autoScroll = setInterval(() => {
      nextSlide()
    }, rotateInterval)

    return () => clearInterval(autoScroll)
  }, [nextSlide, rotateInterval])

  return (
    <HeroBannerCarouselContainer>
      <HeroBannerCarouselNavBtn
        $side="left"
        aria-label={intl.formatMessage({
          id: 'Common.slide.previous',
        })}
        onClick={prevSlide}
        disabled={isTransitioning}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </HeroBannerCarouselNavBtn>

      {/* Clone of last slide placed in front to give illusion of infinite loop */}
      <HeroBannerCarouselTrack
        ref={heroBannerTrackRef}
        $currentSlide={currentSlide}
        $isTransitionEnabled={isTransitioning}
        onTransitionEnd={onTransitionEnd}
      >
        <HeroBannerCarouselSlide
          key="last-banner-slide-clone"
          $hasCta={banners[banners.length - 1].primaryBtnLink ? true : false}
        >
          <HeroBannerCarouselSlideImage
            src={banners[banners.length - 1].image}
            aria-hidden
            alt=""
            loading="eager"
            width={1920}
            height={400}
          />

          <HeroBannerCarouselSlideContent
            $hasCta={banners[banners.length - 1].primaryBtnLink ? true : false}
          >
            <HeroBannerCarouselSlideTitle>
              {banners[banners.length - 1].title}
            </HeroBannerCarouselSlideTitle>
            <HeroBannerCarouselSlideSubtitle>
              {banners[banners.length - 1].subtitle}
            </HeroBannerCarouselSlideSubtitle>

            {banners[banners.length - 1].primaryBtnLink && (
              <HeroBannerCarouselSlidePrimaryBtn
                appearance="primary"
                href={banners[banners.length - 1].primaryBtnLink || ''}
                type="button"
                onClick={() =>
                  handlePrimaryButtonClick(
                    banners[banners.length - 1].primaryBtnLink || '',
                    banners[banners.length - 1].primaryBtnText || ''
                  )
                }
              >
                {banners[banners.length - 1].primaryBtnText}
              </HeroBannerCarouselSlidePrimaryBtn>
            )}
            {banners[banners.length - 1].secondaryBtnLink && (
              <HeroBannerCarouselSlideSecondaryBtn
                appearance="secondary"
                href={banners[banners.length - 1].secondaryBtnLink || ''}
                type="button"
              >
                {banners[banners.length - 1].secondaryBtnText}
              </HeroBannerCarouselSlideSecondaryBtn>
            )}
          </HeroBannerCarouselSlideContent>
        </HeroBannerCarouselSlide>

        {banners.map((banner) => (
          <HeroBannerCarouselSlide
            key={`${banner.title}##${banner.subtitle}`}
            $hasCta={banner.primaryBtnLink ? true : false}
          >
            <HeroBannerCarouselSlideImage
              src={banner.image}
              aria-hidden
              alt=""
              loading="eager"
              width={1920}
              height={400}
            />

            <HeroBannerCarouselSlideContent
              $hasCta={banner.primaryBtnLink ? true : false}
            >
              <HeroBannerCarouselSlideTitle>
                {banner.title}
              </HeroBannerCarouselSlideTitle>
              <HeroBannerCarouselSlideSubtitle>
                {banner.subtitle}
              </HeroBannerCarouselSlideSubtitle>

              {banner.primaryBtnLink && (
                <HeroBannerCarouselSlidePrimaryBtn
                  appearance="primary"
                  href={banner.primaryBtnLink}
                  type="button"
                  onClick={() =>
                    handlePrimaryButtonClick(
                      banner.primaryBtnLink || '',
                      banner.primaryBtnText || ''
                    )
                  }
                >
                  {banner.primaryBtnText}
                </HeroBannerCarouselSlidePrimaryBtn>
              )}
              {banner.secondaryBtnLink && (
                <HeroBannerCarouselSlideSecondaryBtn
                  appearance="secondary"
                  href={banner.secondaryBtnLink}
                  type="button"
                >
                  {banner.secondaryBtnText}
                </HeroBannerCarouselSlideSecondaryBtn>
              )}
            </HeroBannerCarouselSlideContent>
          </HeroBannerCarouselSlide>
        ))}

        {/* Clone of first slide placed at end to give illusion of infinite loop */}
        <HeroBannerCarouselSlide
          key="first-banner-slide-clone"
          $hasCta={banners[0].primaryBtnLink ? true : false}
        >
          <HeroBannerCarouselSlideImage
            src={banners[0].image}
            aria-hidden
            alt=""
            loading="eager"
            width={1920}
            height={400}
          />

          <HeroBannerCarouselSlideContent
            $hasCta={banners[0].primaryBtnLink ? true : false}
          >
            <HeroBannerCarouselSlideTitle>
              {banners[0].title}
            </HeroBannerCarouselSlideTitle>
            <HeroBannerCarouselSlideSubtitle>
              {banners[0].subtitle}
            </HeroBannerCarouselSlideSubtitle>

            {banners[0].primaryBtnLink && (
              <HeroBannerCarouselSlidePrimaryBtn
                appearance="primary"
                href={banners[0].primaryBtnLink}
                type="button"
                onClick={() =>
                  handlePrimaryButtonClick(
                    banners[0].primaryBtnLink || '',
                    banners[0].primaryBtnText || ''
                  )
                }
              >
                {banners[0].primaryBtnText}
              </HeroBannerCarouselSlidePrimaryBtn>
            )}
            {banners[0].secondaryBtnLink && (
              <HeroBannerCarouselSlideSecondaryBtn
                appearance="secondary"
                href={banners[0].secondaryBtnLink}
                type="button"
              >
                {banners[0].secondaryBtnText}
              </HeroBannerCarouselSlideSecondaryBtn>
            )}
          </HeroBannerCarouselSlideContent>
        </HeroBannerCarouselSlide>
      </HeroBannerCarouselTrack>

      <HeroBannerCarouselNavBtn
        $side="right"
        aria-label={intl.formatMessage({
          id: 'Common.slide.next',
        })}
        onClick={nextSlide}
        disabled={isTransitioning}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </HeroBannerCarouselNavBtn>
    </HeroBannerCarouselContainer>
  )
}

export default HeroBannerCarousel
