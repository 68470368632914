'use client'
// [Best Place to Purchase - WRH-32]
import {
  faBoxesStacked,
  faWindowMaximize,
  faUserGroup,
} from '@fortawesome/pro-light-svg-icons'
import { faBan } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { Breakpoints, Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

const StyledSection = styled.section<{ $bgColor?: string }>`
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
  display: flex;
  padding: 5rem 2.5rem;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  @media ${Breakpoints.max.small} {
    padding: 2.5rem 0;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${Breakpoints.max.medium} {
    justify-content: center;
    width: 52.125rem;
  }

  @media ${Breakpoints.max.small} {
    width: 100%;
    max-width: 100%;
    padding: 1rem;
    margin: 0 auto;
  }
`

const PreTitle = styled.h2`
  ${Typography.Subtitle2};
  font-weight: 600;
  color: ${designColors.primary.default};
  text-transform: uppercase;
  margin: 0 auto 0.625rem;
  padding: 0;
`

const Title = styled.h3`
  ${Typography.Display};
  font-weight: 600;
  color: ${designColors.neutral.veryDarkGray};
  margin: 0 auto 2.5rem;
  padding: 0;

  @media ${Breakpoints.max.small} {
    ${Typography.Title1};
  }
`

const CardContainer = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75rem;
  max-width: 100%;
  gap: 4rem;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    gap: 1rem;
    padding: 0;
  }
`

const Card = styled.div`
  display: flex;
  padding: 0.9375rem;
  flex-direction: column;
  gap: 0.625rem;
  border-radius: 1rem;
  flex: 1;
  width: calc((100% - 8rem) / 4);
  max-width: calc((100% - 8rem) / 4);
  background-color: ${designColors.neutral.white};

  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
`

const CardTitle = styled.h4`
  ${Typography.Subtitle1};
  color: ${designColors.neutral.veryDarkGray};
  margin: 0;
  padding: 0;
`

const FAContainer = styled.div`
  width: 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
  max-height: 2.5rem;
  background-color: ${designColors.neutral.white};
`

export const StyledFAIcon = styled(FontAwesomeIcon)<{ $flipped?: boolean }>`
  color: ${designColors.primary.brightBlue};
  font-size: 2.5rem;
  transform: ${({ $flipped }) => ($flipped ? 'scaleX(-1)' : 'none')};
`

const CardText = styled.p`
  ${Typography.Body2};
  color: ${designColors.neutral.veryDarkGray};
  padding: 0;
  margin: 0;
`

export default function BestPlaceToPurchase() {
  return (
    <StyledSection>
      <Content>
        <PreTitle>Source Inventory for Sale</PreTitle>
        <Title>B-Stock: The best place to find inventory for resale</Title>
        <CardContainer>
          <Card>
            <FAContainer>
              <StyledFAIcon icon={faWindowMaximize} />
            </FAContainer>
            <CardTitle>No middleman</CardTitle>
            <CardText>
              Buy direct from retailers through their official liquidation
              marketplaces.
            </CardText>
          </Card>
          <Card>
            <FAContainer>
              <StyledFAIcon icon={faBoxesStacked} />
            </FAContainer>
            <CardTitle>All lot sizes, categories</CardTitle>
            <CardText>
              Source across all lot sizes, product categories, and price points.
            </CardText>
          </Card>
          <Card>
            <FAContainer>
              <StyledFAIcon icon={faBan} $flipped />
            </FAContainer>
            <CardTitle>No Surprises</CardTitle>
            <CardText>
              From detailed listings to itemized manifests, you&#39;ll know the
              quantity.
            </CardText>
          </Card>
          <Card>
            <FAContainer>
              <StyledFAIcon icon={faUserGroup} $flipped />
            </FAContainer>
            <CardTitle>We&#39;ve got your back!</CardTitle>
            <CardText>
              A dedicated customer service team and education center to help you
              grow your business.
            </CardText>
          </Card>
        </CardContainer>
      </Content>
    </StyledSection>
  )
}
