'use client'

import { styled } from 'styled-components'

import { ButtonAsA } from '@b-stock/bstock-react'
import { Breakpoints, Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

import AuctionCardSkeleton from '@components/common/auctionCard/AuctionCardSkeleton'
import { useAuctionQuery } from '@helpers/useAuctionQuery'

import AuctionCard from '../common/auctionCard/AuctionCard'
import Carousel, {
  CarouselContainer,
  CarouselItemWrapper,
} from '../common/carousel/Carousel'

export const ShopMarketplaceCarouselContainer = styled.div`
  display: flex;
  padding: 1.875rem 2.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;

  @media ${Breakpoints.max.small} {
    padding: 0.3125rem 0;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 85rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;

  ${CarouselContainer} {
    width: 100%;
    max-width: 77.75rem;
    @media ${Breakpoints.max.small} {
      width: 100%;
      max-width: 100%;
    }
  }

  ${CarouselItemWrapper} {
    padding: 0 0.3125rem;
  }

  @media ${Breakpoints.max.medium} {
    justify-content: center;
  }

  @media ${Breakpoints.max.small} {
    gap: 1.25rem;
    width: 100%;
    max-width: 100%;
    padding: 1rem;
    margin: 0 auto;
  }
`

const ContentHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 77.75rem;
  padding: 0 1.6875rem;

  & > *:first-child {
    margin-right: auto;
  }

  @media ${Breakpoints.max.small} {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
`
const Title = styled.h3`
  ${Typography.Title2};
  font-weight: 600;
  color: ${designColors.neutral.veryDarkGray};
  margin: 0;
  padding: 0;
  white-space: nowrap;

  @media ${Breakpoints.max.small} {
    ${Typography.Subtitle1};
  }
`
const ViewMore = styled(ButtonAsA)`
  white-space: nowrap;
  margin: 0;
  padding: 0;

  &&:hover {
    background-color: transparent;
  }
  @media ${Breakpoints.max.small} {
    display: none;
  }
`

const ViewMoreMobile = styled(ViewMore)`
  display: none;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  @media ${Breakpoints.max.small} {
    display: block;
  }
`

export interface ShopMarketplaceCarouselProps {
  title: string
  viewMoreUrl: string
  endpoint: string | string[]
  minItemsToShowButtons?: number
}

const TitleSkeleton = styled.div`
  width: 12.5rem;
  height: 1.5rem;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 0.25rem;

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`

const ViewMoreSkeleton = styled(TitleSkeleton)`
  width: 6.25rem;
  height: 1.25rem;
`

export default function ShopMarketplaceCarousel({
  title,
  viewMoreUrl,
  endpoint,
}: ShopMarketplaceCarouselProps) {
  const { data: auctions, isLoading } = useAuctionQuery(endpoint)

  const skeletonCount = 10

  if (!isLoading && (!auctions || auctions.length === 0)) {
    return null
  }

  const auctionCards = isLoading
    ? Array(skeletonCount)
        .fill(null)
        .map((_, index) => (
          <AuctionCardSkeleton key={`skeleton-${index + 1}`} />
        ))
    : auctions?.map((auction) => (
        <AuctionCard key={auction.auctionId} auction={auction} />
      )) || []

  return (
    <ShopMarketplaceCarouselContainer>
      <Content>
        <ContentHeader>
          {isLoading ? <TitleSkeleton /> : <Title>{title}</Title>}
          {isLoading ? (
            viewMoreUrl ? (
              <ViewMoreSkeleton />
            ) : null
          ) : viewMoreUrl ? (
            <ViewMore size="large" appearance="tertiary" href={viewMoreUrl}>
              View More
            </ViewMore>
          ) : null}
        </ContentHeader>
        <Carousel disabled={isLoading}>{auctionCards}</Carousel>
        {viewMoreUrl && !isLoading && (
          <ViewMoreMobile size="large" appearance="tertiary" href={viewMoreUrl}>
            View More
          </ViewMoreMobile>
        )}
      </Content>
    </ShopMarketplaceCarouselContainer>
  )
}
