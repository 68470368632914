'use client'
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import Link from 'next/link'
import { styled } from 'styled-components'

import { Duration } from '@b-stock/bstock-next'
import { Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

import { parseCustomDate } from '@helpers/dateUtils'
import { sendGTMEvent } from '@helpers/telemetry/GoogleTagManager'
import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

export interface Auction {
  seller: string
  siteUrl: string
  sellerCountry: string
  siteName: string
  numberOfBids: string
  endTime: string
  inventoryType: string
  inspection: string
  retailPrice: string
  image_url: string
  auctionUrl: string
  manufacturer: string
  service_type: string
  condition: string
  primaryImageUrl: string
  winningBidAmount: string
  categories: string
  sellerRegion: string
  shipmentType: string
  siteLogoUrl: string
  siteAbb: string
  auctionId: string
  sku: string
  bfile_enabled: string
  sellerId: string
  startTime: string
  title: string
  state: string
  lat: string
  lng: string
  distance: number | null
  auction_type: string
}

interface IProps {
  auction: Auction
}

interface AuctionLinkProps {
  title?: string
  auction_id?: string
  item_type?: string
  item_marketplace?: string
  item_brand?: string
  item_category?: string
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 12.375rem;
  max-width: 12.375rem;
  height: 21.25rem;
  max-height: 21.25rem;
  padding: 0;
  flex-shrink: 0;
`

const AuctionLink = styled(Link)<AuctionLinkProps>`
  position: relative;
  display: block;
  height: 100%;

  &:before {
    position: absolute;
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
    content: '';
    border-radius: 0.375rem;
    border: 0.125rem solid transparent;
    transition: border-color 0.3s ease;
    pointer-events: none;
  }

  &:hover:before {
    border-color: ${designColors.primary.brightBlue};
  }
`

const LogoBlock = styled.div`
  display: flex;
  height: 3.125rem;
  max-height: 3.125rem;
  padding: 0 1.0625rem;
  border: 0.0625rem solid ${designColors.neutral.mediumGray};
  border-bottom: none;
  border-radius: 0.3125rem 0.3125rem 0 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
`
const LogoImage = styled(Image)`
  padding: 0.3125rem 0;
  object-fit: contain;
`

const AuctionImageHolder = styled.div`
  display: flex;
  height: 8.75rem;
  max-height: 8.75rem;
  margin: 0;
  padding: 0;
  border: 0.0625rem solid ${designColors.neutral.mediumGray};
  justify-content: center;
  align-items: center;
`

const AuctionImage = styled(Image)`
  width: 7.1875rem;
  height: 7.1875rem;
  max-width: 95%;
  max-height: 95%;
`

const LotInfo = styled.div`
  min-height: 9.375rem;
  max-height: 9.375rem;
  padding: 1.375rem 0.9375rem 0.9375rem;
  border: 0.0625rem solid ${designColors.neutral.mediumGray};
  border-top: none;
  border-radius: 0 0 0.3125rem 0.3125rem;
  text-align: left;
  font-size: 0.875rem;
`
const ArrowIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 0.9375rem;
  bottom: 0.625rem;
  font-size: 1.25rem;
  width: 1.25rem;
  max-width: 1.25rem;
  color: ${designColors.primary.brightBlue};
`
const Title = styled.p`
  ${Typography.Body3}
  display: -webkit-box;
  margin: 0;
  margin-bottom: 0.6875rem;
  padding: 0;
  line-height: 1.0625rem;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const Bids = styled.p`
  ${Typography.Body3}
  margin: 0;
  padding: 0;
  line-height: 1.0625rem;
`
const Closes = styled(Bids)``

const AuctionCard = ({ auction }: IProps) => {
  //For Amazon US and UK auctions ONLY
  const logoUrl =
    auction.siteName === 'Amazon US'
      ? '/home-portal/images/marketplaceLogos/amazon_us.png'
      : auction.siteName === 'Amazon UK'
        ? '/home-portal/images/marketplaceLogos/amazon_eu.png'
        : auction.siteLogoUrl

  const endTime = parseCustomDate(auction.endTime)
  const { trackButtonClicked } = useAnalytics()

  const handleClickAuctionCard = () => {
    // Segment analytics event and metadata transcribed from old wordpress code
    // TODO: Update entity_type to based on the role of the user once we
    // implement user authentication.
    trackButtonClicked(
      'homepage', // screen_name
      'homepage_listing', // button_name
      'home_portal', // source
      'buyer', // entity_type
      {
        auction_id: auction.auctionId,
        // TODO: there does not appear to be an analog for these fields in the ai-search endpoint
        // listing_source: auction.inventoryType,
        // seller_name_temp: auction.inventoryType,
        seller_name_ua: auction.seller,
        carousel_name: null, // TODO: determine which carousel the auction card belongs to
        url: document.URL,
        referrer: document.referrer,
        cta_url: auction.auctionUrl,
      }
    )

    // Ignore the automatic GTM event by applying the data-gtm-ignore attribute
    // to the wrapper div, so we can send our own event below.
    sendGTMEvent({
      event: 'select_item',
      ecommerce: {
        items: [
          {
            item_id: auction.auctionId,
            item_name: auction.title.substring(0, 100),
            item_brand: auction.manufacturer,
            item_category: auction.categories,
            item_list_name: 'All Auctions',
            // TODO: The ai-search endpoint does not appear to expose the item's currency
            currency: 'USD',
          },
        ],
      },
    })
  }

  return (
    <Wrapper data-gtm-ignore>
      <AuctionLink
        href={auction.auctionUrl}
        title={auction.title}
        target="_blank"
        rel="noopener"
        onClick={handleClickAuctionCard}
      >
        <LogoBlock>
          <LogoImage
            src={logoUrl}
            alt={auction.siteName}
            fill
            sizes="85%"
            priority
          />
        </LogoBlock>
        <AuctionImageHolder>
          <AuctionImage
            src={auction.primaryImageUrl}
            alt={auction.seller}
            width={115}
            height={115}
          />
        </AuctionImageHolder>
        <LotInfo>
          <Title>{auction.title}</Title>
          <Bids>
            Bids <strong>{auction.numberOfBids}</strong>
          </Bids>
          <Closes>
            Closes in{' '}
            <strong>
              <Duration target={endTime} format="short" />
            </strong>
          </Closes>
          <ArrowIcon icon={faArrowRightLong} />
        </LotInfo>
      </AuctionLink>
    </Wrapper>
  )
}

export default AuctionCard
