export interface Seller {
  logo: string
  name: string
  link: string
  color: string
}

// The colors here are not currently in our designColors.

const baseSellerData: Seller[] = [
  {
    logo: '/home-portal/images/sellerIcons/amazon-cir.png',
    name: 'Amazon',
    link: '/amazon/',
    color: '#FFFAEE',
  },
  {
    logo: '/home-portal/images/sellerIcons/Target.png',
    name: 'Target',
    link: '/target/',
    color: '#ffeaee',
  },
  {
    logo: '/home-portal/images/sellerIcons/att-logo.png',
    name: 'Mobile Carrier',
    link: '/mobilecarrier/',
    color: '#dff4ff',
  },
  {
    logo: '/home-portal/images/sellerIcons/costcoappliances.png',
    name: 'Costco Appliances',
    link: '/buy/sellers/details/costcoappliances',
    color: '#ffeaee',
  },
  {
    logo: '/home-portal/images/sellerIcons/Unilever.png',
    name: 'Unilever',
    link: '/unilever/',
    color: '#F0F5FF',
  },
  {
    logo: '/home-portal/images/sellerIcons/Bjs.png',
    name: "BJ's",
    link: '/buy/sellers/details/bjs',
    color: '#ffebec',
  },
  {
    logo: '/home-portal/images/sellerIcons/Ge.png',
    name: 'GE',
    link: '/geappliances/',
    color: '#F0F5FF',
  },
  {
    logo: '/home-portal/images/sellerIcons/walmart.png',
    name: 'Walmart',
    link: 'https://liquidations.walmart.com/',
    color: '#ffebec',
  },
  {
    logo: '/home-portal/images/sellerIcons/samsung.png',
    name: 'Samsung',
    link: '/samsung/',
    color: '#F0F5FF',
  },
  {
    logo: '/home-portal/images/sellerIcons/costco.png',
    name: 'Costco',
    link: '/costco/',
    color: '#ffeaee',
  },
]

export const sellersSpotlight = Array.from({ length: 9 }, (_, index) => {
  const baseSeller = baseSellerData[index % baseSellerData.length]
  return {
    ...baseSeller,
    key: `${baseSeller.name} ${Math.floor(index / baseSellerData.length) + 1}`,
  }
})
