'use client'
import type { ReactNode } from 'react'

import { styled } from 'styled-components'

import { Breakpoints } from '@b-stock/bstock-react/design-system'

const ShopMarketplaceGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 5rem 2.5rem;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;

  @media ${Breakpoints.max.small} {
    padding: 2.5rem 0;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
`

interface ShopMarketplaceGroupProps {
  children: ReactNode
}

export default function ShopMarketplaceGroup({
  children,
}: ShopMarketplaceGroupProps) {
  return (
    <ShopMarketplaceGroupContainer>{children}</ShopMarketplaceGroupContainer>
  )
}
