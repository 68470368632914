'use client'
// [Other Buying Approaches - WRH-9]
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import { styled } from 'styled-components'

import { ButtonAsA } from '@b-stock/bstock-react'
import { Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

const StyledSection = styled.section<{ $bgColor?: string }>`
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
  display: flex;
  padding: 5rem 2.5rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 769px) {
    padding: 2.5rem 1.5rem;
  }
`

const Content = styled.div`
  width: 67rem;
  max-width: 67rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  padding: 0 2.5rem;

  @media screen and (max-width: 1200px) {
    width: 100%;
    padding: 0;
  }
`

const Title = styled.h3`
  ${Typography.Display};
  font-weight: 600;
  color: ${designColors.neutral.veryDarkGray};
  margin: 0;
  padding: 0;

  @media screen and (max-width: 769px) {
    font-size: 1.5rem;
  }
`

const CardContainer = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
`

const Card = styled.div<{ $bgColor?: string }>`
  display: flex;
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  border-radius: 1rem;
  flex: 1;
  width: calc(50% - 1rem);
  max-width: 28.75rem;
  max-height: fit-content;
  background-color: ${({ $bgColor }) => $bgColor || designColors.neutral.white};

  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
  }

  @media screen and (max-width: 769px) {
    width: 100%;
    padding: 1.5rem;
  }
`

const CardTopRow = styled.div``

const CardTitle = styled.h4`
  ${Typography.Title2};
  color: ${designColors.neutral.veryDarkGray};
  height: fit-content;
  margin: 1.5rem 0 0.75rem 0;
  padding: 0;
`

const ImageContainer = styled.div`
  width: 9.25rem;
  height: 7.1875rem;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0;
  margin: 0;

  overflow: hidden;
  position: relative;
`

const StyledImage = styled(Image)`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
  object-fit: cover;

  width: 100%;
  height: 100%;
`

const StyledButton = styled(ButtonAsA)`
  width: fit-content;
  max-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  white-space: nowrap;

  background-color: ${designColors.neutral.white};
  color: ${designColors.primary.default};

  &&:hover {
    color: ${designColors.neutral.white};
    background-color: ${designColors.primary.default};
  }
`

const CardText = styled.p`
  ${Typography.Body2};
  color: ${designColors.neutral.veryDarkGray};
  padding: 0;
  margin: 0 0 1.5rem 0;
  width: 17.1875rem;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`

export default function ExploreBuying() {
  return (
    <StyledSection $bgColor={designColors.neutral.white}>
      <Content>
        <Title>Explore Other Buying Approaches</Title>
        <CardContainer>
          <Card $bgColor={designColors.primary.backgroundLightBlue}>
            <CardTopRow>
              <ImageContainer>
                <StyledImage
                  id="Contracts"
                  src="/home-portal/images/contracts.png"
                  alt="B-Stock Solutions, LLC."
                  width={148}
                  height={140}
                />
              </ImageContainer>
              <CardTitle>Contracts</CardTitle>
            </CardTopRow>
            <CardText>
              Shop medium and long-term purchasing contracts directly from the
              world&#39;s largest retailers and manufacturers
            </CardText>
            <StyledButton
              appearance="primary"
              size="large"
              href="/direct/"
              target="_blank"
              rel="noopener"
            >
              Start Shopping <FontAwesomeIcon icon={faLongArrowRight} />
            </StyledButton>
          </Card>
          <Card $bgColor="#FFF9E5">
            <CardTopRow>
              <ImageContainer>
                <StyledImage
                  id="Spot Sales"
                  src="/home-portal/images/flash_sales.png"
                  alt="B-Stock Solutions, LLC."
                  width={148}
                  height={140}
                />
              </ImageContainer>
              <CardTitle>Spot Sales</CardTitle>
            </CardTopRow>
            <CardText>
              Browse one-time offers from Fortune 500 retailers and
              manufacturers. Sizes range from parcels and single pallets to full
              truckloads
            </CardText>
            <StyledButton
              appearance="primary"
              size="large"
              href="/all-auctions/"
              target="_blank"
              rel="noopener"
            >
              Start Shopping <FontAwesomeIcon icon={faLongArrowRight} />
            </StyledButton>
          </Card>
        </CardContainer>
      </Content>
    </StyledSection>
  )
}
